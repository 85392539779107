import { localeRegex } from "~/tax-engine/i18n-utils"

export const notFoundResponse = async (url: URL) => {
  const res = await fetch(new URL("/404", url))
  const text = await res.text()

  return new Response(text, {
    status: 404,
    statusText: "Not Found", // eslint-disable-line lingui/no-unlocalized-strings
    headers: {
      "Content-Type": "text/html",
    },
  })
}

/* 
  Mantine <Image> src/srcSet prop examples:
  - src="/images/foo.jpg" (no locale, one density)
  - src={getSrc("/images/hero.jpg")} (with locale, one density)
  - srcSet={getSrcSet("/images/hero.jpg")} (with locale, two densities)
  - srcSet={getSrcSet("/images/hero.jpg", false)} (no locale, two densities)
  - {...imageSrc("/images/hero.jpg")} (with locale, two densities)
  - {...imageSrc("/images/hero.jpg", false)} (no locale, two densities)
  */

// name_locale_density.extension
export const getSrc = (image: string, density?: string) => {
  const locale = window.location.pathname.match(localeRegex)?.[1] ?? "en"
  const lastDot = image.lastIndexOf(".")
  return `${image.substring(0, lastDot)}_${locale}${density ? `_${density}` : ``}${image.substring(lastDot)}`
}

export const getSrcSet = (image: string, withLocale: boolean = true) => {
  const density = "2x"
  const lastDot = image.lastIndexOf(".")
  return withLocale
    ? `${getSrc(image)}, ${getSrc(image, density)} ${density}`
    : `${image}, ${image.substring(0, lastDot)}_${density}${image.substring(lastDot)} ${density}`
}

export const imageSrc = (image: string, withLocale?: boolean) => {
  return {
    src: getSrc(image),
    srcSet: getSrcSet(image, withLocale),
  }
}
